import React from "react";
import { graphql } from "gatsby";
import BlogSingle from "../components/routes/Blog/BlogSingle";
// import { WmkSeo } from "wmk-lib";
import { baseUrl, twitter } from "../vars/ThemeOptions";
import Layout from "../components/layout/MainLayout";
import { WmkSeo } from "wmk-seo";
import { BlogExcerptQuery, NodeBlogQuery } from "../fragments/NodeBlogFields";

interface BlogNodeData {
  related: {
    edges: {
      node: BlogExcerptQuery;
    }[];
  };
  blog: NodeBlogQuery;
}

const BlogNode = ({ data }: { data: BlogNodeData }) => {
  const { blog } = data;
  //console.log(blog,related)

  return (
    <Layout>
      <WmkSeo.Meta
        title={blog.title}
        path={"/"}
        slug={"blog/" + blog.slug}
        //description={description}
        ogImageUrl={blog?.ogImage?.file?.url ? blog?.ogImage?.file?.url : ""}
        twitterImageUrl={
          blog?.twitterImage?.file?.url ? blog?.twitterImage?.file?.url : ""
        }
        twitterHandle={twitter}
        siteTitle={"ESG Blog"}
        baseUrl={baseUrl}
      />
      <BlogSingle blog={blog} />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $tags: [String!]) {
    related: allContentfulBlogs(
      filter: { tags: { in: $tags }, slug: { ne: $slug } }
      limit: 4
    ) {
      edges {
        node {
          ...NodeBlogExcerptFields
        }
      }
    }
    blog: contentfulBlogs(slug: { eq: $slug }) {
      ...NodeBlogFields
    }
  }
`;

export default BlogNode;

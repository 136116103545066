import React from "react";
// import Img from "gatsby-image";
//import SubHeading from "../../common/typography/SubHeading";
import { Row, Col } from "react-bootstrap";
// import RichText from "../../../richText/RichText";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import { RichText, RichTextReact } from "wmk-rich-text";
import options from "../../../richText/richTextOptions";
import { NodeBlogQuery } from "../../../fragments/NodeBlogFields";
import { Img, WmkImage } from "wmk-image";

const Styled = {};
const BlogContent = styled(Col)`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: 680px;
    margin: 2vh auto 3vh auto;
    padding: 0 0.5rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Rajdhani", sans-serif;
    color: ${colors.hex("secondary")};
  }
  ul {
    li {
      & > .row > .col > p {
        font-weight: bold;
        margin: 1.5vh 0;
      }
    }
  }
  p {
    font-size: 1.25rem;
  }
  figure {
    .embedded-image {
      max-width: none;
      max-height: 100vh;
      width: 100%;
      object-fit: cover;
    }
  }
  figcaption {
    text-align: center;
  }
  @media only screen {
    figcaption {
      display: none;
    }
  }
`;

const Header = styled(Col)`
  .gatsby-image-wrapper {
    width: 100% !important;
    min-height: 10rem;
    // margin-top: 3.5rem;
    height: 400px;
  }
`;

const Title = styled.h1`
  font-family: Rajdhani, sans-serif;
  color: ${colors.hex("text")};
`;

const BlogSingle = ({ blog }: { blog: NodeBlogQuery }) => {
  const { title, heroImage, content } = blog;
  // const { fixed } = heroImage ? heroImage : { fixed: null };
  const heroImg = new Img(heroImage);
  const richTextContent = content ? new RichText(content) : null;
  return (
    <Row className="flex-column">
      <Col
        style={{
          display: "block",
          textAlign: "center",
          padding: "2vh",
          marginTop: "1vh",
        }}
      >
        <Title>{title}</Title>
      </Col>
      <Header>
        <WmkImage image={heroImg} />
        {/* {fixed && <Img fixed={fixed} alt={title} />} */}
      </Header>
      <BlogContent className="serif">
        {richTextContent && (
          <RichTextReact content={richTextContent} options={options} />
        )}
      </BlogContent>
    </Row>
  );
};

export default BlogSingle;
